<template>
  <v-container class="ma-0 pa-0 clear-background" fluid>
      
      <!-- Header -->
          <v-layout class="primary-background" :class="{'center': $vuetify.breakpoint.xlOnly}">
              <div class="max-width-1200 ma-auto" :class="{'ml-0': $vuetify.breakpoint.mdOnly, 'ml-0': $vuetify.breakpoint.lgOnly, 'mx-12': $vuetify.breakpoint.xlOnly}">
                  <v-layout row wrap mt-12 :class="{'mx-12': $vuetify.breakpoint.xsOnly, 'mx-4 px-4': $vuetify.breakpoint.xlOnly, 'mr-12': $vuetify.breakpoint.mdAndUp}">
                    <v-flex v-if="$vuetify.breakpoint.mdAndUp" md5><v-img :src="building" width="400px"></v-img></v-flex>
                    <v-flex sm8 md6 offset-sm1>
                        <h3 class="antenna font-48 white--text" :class="{'font-34': $vuetify.breakpoint.smAndDown}">{{ $ml.get('solutions_title') }} </h3>
                        <p class="avenir font-18 white--text mb-0">  {{ $ml.get('solutions_header_text') }}</p>
                        <v-btn class="btn-text btn-header max-width-200 my-8" depressed @click="$goTo('/contactUs', 'contact')"> 
                            {{ $ml.get('button_apply') }} 
                            <v-img :src="right_arrow" :lazy-src="right_arrow" style="width:18px; cursor:pointer" class="ml-2" ></v-img>
                        </v-btn>
                    </v-flex>
                  </v-layout>
              </div>
          </v-layout>


      <!-- Years -->
            <v-layout row wrap mx-12 mt-12 px-6 id="commitment" v-if="$vuetify.breakpoint.smAndUp"> 
                <div class="max-width-1200 ma-auto">
                    <p class="venera font-19 primary-color mb-0">{{ $ml.get('company') }}</p>
                    <v-layout row wrap>   
                        <v-flex sm6 md4 class="pa-6" @click="showText(1)" style="cursor:pointer">
                            <div class="image-container hover">
                            <img v-bind:class="{'blue-overlay': !year_1_text}" :src="year1" style="width:100%; height:250px;">
                            <div class="centered" v-if="!year_1_text"><p class="venera-bold font-30 white--text">2010</p></div>
                            </div>
                        </v-flex>

                        <v-flex sm6 md4 class="pa-6" @click="showText(2)" style="cursor:pointer">
                        <div class="image-container hover">
                            <img v-bind:class="{'blue-overlay': !year_2_text}" :src="year2" style="width:100%; height:250px;">
                            <div class="centered" v-if="!year_2_text"><p class="venera-bold font-30 white--text">2015</p></div>
                            </div>
                        </v-flex>

                        <v-flex sm6 md4 class="pa-6" @click="showText(3)" style="cursor:pointer">
                        <div class="image-container hover">
                            <img v-bind:class="{'blue-overlay': !year_3_text}" :src="year3" style="width:100%; height:250px;">
                            <div class="centered" v-if="!year_3_text"><p class="venera-bold font-30 white--text">2019</p></div>
                            </div>
                        </v-flex>


                        <Animated enter="fadeInDown" duration="500">
                            <v-flex md8 pa-6 v-show="year_1_text">
                                <p class="antenna font-37 primary-color mb-0">{{$ml.get('solutions_year_1_title')}}</p>
                                <p class="avenir primary-color font-16 mb-0">{{ $ml.get('solutions_year_1_text') }}</p>
                            </v-flex>
                        </Animated>
                        <Animated enter="fadeInDown" duration="500">
                            <v-flex md8 pa-6 v-show="year_2_text">
                                <p class="antenna font-37 primary-color mb-0">{{$ml.get('solutions_year_2_title')}}</p>
                                <p class="avenir primary-color font-16 mb-0">{{ $ml.get('solutions_year_2_text') }}</p>
                            </v-flex>
                        </Animated>
                        <Animated enter="fadeInDown" duration="500">
                            <v-flex sm8 pa-6 v-show="year_3_text">
                                <p class="antenna font-37 primary-color mb-0">{{$ml.get('solutions_year_3_title')}}</p>
                                <p class="avenir primary-color font-16 mb-0">{{ $ml.get('solutions_year_3_text') }}</p>
                            </v-flex>
                        </Animated>
                    </v-layout>
                </div>
            </v-layout>

             <!-- Years mobile -->
            <v-layout row wrap ma-3 pa-6 id="commitment" v-if="$vuetify.breakpoint.xsOnly"> 
                <div class="max-width-1200 ma-auto">
                    <p class="venera font-19 primary-color mb-6">{{ $ml.get('company') }}</p>
                    <v-layout row wrap>   
                        <v-flex md4>
                            <v-divider class="my-6 divider"></v-divider>
                            <img :src="year1" style="width:100%; height:250px;">
                                <p class="venera font-16 primary-color mt-4 mb-0">2010</p>
                                <p class="antenna font-28 primary-color mb-0">{{$ml.get('solutions_year_1_title')}}</p>
                                <p class="avenir primary-color font-16 mb-0">{{ $ml.get('solutions_year_1_text') }}</p>
                        </v-flex>

                       <v-flex md4>
                            <v-divider class="my-6 divider"></v-divider>
                            <img :src="year2" style="width:100%; height:250px;">
                                <p class="venera font-16 primary-color mt-4 mb-0">2015</p>
                                <p class="antenna font-28 primary-color mb-0">{{$ml.get('solutions_year_2_title')}}</p>
                                <p class="avenir primary-color font-16 mb-0">{{ $ml.get('solutions_year_2_text') }}</p>
                        </v-flex>

                        <v-flex md4>
                            <v-divider class="my-6 divider"></v-divider>
                            <img :src="year3" style="width:100%; height:250px;">
                                <p class="venera font-16 primary-color mt-4 mb-0">2019</p>
                                <p class="antenna font-28 primary-color mb-0">{{$ml.get('solutions_year_3_title')}}</p>
                                <p class="avenir primary-color font-16 mb-0">{{ $ml.get('solutions_year_3_text') }}</p>
                                <v-divider class="my-6 divider"></v-divider>
                        </v-flex>
                    </v-layout>
                </div>
            </v-layout>



      <!-- World map -->
      
        <v-layout row wrap :class="{'pa-6': $vuetify.breakpoint.smAndUp}">
            <div class="max-width-1200 ma-auto">
                <v-layout row wrap justify-center :class="{'map-layout': $vuetify.breakpoint.xsOnly}">
                    
                    <v-flex v-if="$vuetify.breakpoint.xsOnly">
                        <v-layout ma-12 pt-4 row wrap>
                            <v-flex>
                                <div>
                                    <p class="venera-bold font-16 office-text mb-2">{{$ml.get('about_offices')}}:</p>
                                    <ul>
                                        <li class="antenna font-14 office-text">{{$ml.get('monterrey')}}</li>
                                        <li class="antenna font-14 office-text">{{$ml.get('sao_pablo')}}</li>
                                    </ul>
                                </div>
                            </v-flex>
                            <v-flex>
                                <div>
                                    <p class="venera-bold font-16 primary-color mb-2">{{$ml.get('about_projects')}}:</p>
                                    <ul>
                                        <li class="antenna font-14 primary-color">{{$ml.get('usa')}}</li>
                                        <li class="antenna font-14 primary-color">{{$ml.get('canada')}}</li>
                                        <li class="antenna font-14 primary-color">{{$ml.get('nigeria')}}</li>
                                        <li class="antenna font-14 primary-color">{{$ml.get('spain')}}</li>
                                        <li class="antenna font-14 primary-color">{{$ml.get('panama')}}</li>
                                    </ul>
                                </div>
                            </v-flex>
                            
                        </v-layout>
                    </v-flex>

                <v-flex md8 pa-4>
                    <div class="is-hidden-mobile" style="position:relative">
                        <section class="distribution-map" v-scroll-reveal.reset>
                            <img class="change-my-color" src="../assets/img/images/world-map2.svg">
                            <button class="map-point project" style="top:40%;left:16%"/>
                            <button class="map-point office" style="top:57.5%;left:19.4%"/>
                            <button class="map-point office" style="top:80%;left:33.1%"/>
                            <button class="map-point project" style="top:51%;left:45%"/>
                            <button class="map-point project" style="top:67%;left:50%"/>
                            <button class="map-point project" style="top:50%;left:20%"/>
                            <button class="map-point project" style="top:64%;left:26%"/>
                        </section>
                    </div>
                    
                </v-flex>

                <v-flex md4 px-12 style="display: flex; align-items: flex-end;" class="pa-4">
                    
                    <v-layout row wrap :class="{'map-layout': $vuetify.breakpoint.xsOnly}">
                        <v-flex xs12 style="display: flex; justify-content: flex-end; align-items: baseline;" mr-2>
                            <button class="point office mr-2"></button>
                            <p class="antenna font-16 office-text mb-0">{{ $ml.get('about_offices') }}</p>
                        </v-flex>
                        <v-flex xs12 style="display: flex; justify-content: flex-end; align-items: baseline;">
                            <button class="point project mr-2"></button>
                            <p class="antenna font-16 primary-color mb-0">{{ $ml.get('about_projects') }}</p>
                        </v-flex>
                        <v-flex ma-4 :class="{'align-right my-12': $vuetify.breakpoint.smAndUp}">
                            <p class="antenna font-23 primary-color mb-0">  {{ $ml.get('solutions_map_title') }}</p>
                            <p class="avenir primary-color font-16 mb-0">  {{ $ml.get('solutions_map_text') }}</p>
                        </v-flex>

                        <v-flex mt-12 v-if="$vuetify.breakpoint.mdAndUp">
                            <v-layout row wrap>
                                <v-flex style="display: flex; justify-content: flex-end;">
                                    <div>
                                        <p class="venera-bold font-16 office-text mb-2">{{$ml.get('about_offices')}}:</p>
                                        <ul>
                                            <li class="antenna font-14 office-text">{{$ml.get('monterrey')}}</li>
                                            <li class="antenna font-14 office-text">{{$ml.get('sao_pablo')}}</li>
                                        </ul>
                                    </div>
                                </v-flex>
                                <v-flex style="display: flex; justify-content: flex-end;">
                                    <div>
                                        <p class="venera-bold font-16 primary-color mb-2">{{$ml.get('about_projects')}}:</p>
                                        <ul>
                                            <li class="antenna font-14 primary-color">{{$ml.get('usa')}}</li>
                                            <li class="antenna font-14 primary-color">{{$ml.get('canada')}}</li>
                                            <li class="antenna font-14 primary-color">{{$ml.get('nigeria')}}</li>
                                            <li class="antenna font-14 primary-color">{{$ml.get('spain')}}</li>
                                            <li class="antenna font-14 primary-color">{{$ml.get('panama')}}</li>
                                        </ul>
                                    </div>
                                </v-flex>
                                
                            </v-layout>
                        </v-flex>
                    
                    </v-layout>
                    </v-flex>

            </v-layout>
            </div>
          </v-layout>


    <!-- Images -->

      <div class="white-background" id="services">
          <div class="max-width-1200 ma-auto">
            <v-layout row wrap :class="{'pa-6 mx-12': $vuetify.breakpoint.smAndUp}">
                <v-flex xs12 my-12 :class="{'mx-12': $vuetify.breakpoint.xsOnly}">
                        <p class="venera font-19 primary-color mb-0">{{ $ml.get('services') }}</p>
                        <p class="antenna font-34 primary-color mb-0">{{$ml.get('solutions_services_title')}}</p>
                        <v-layout>
                            <v-flex md8>
                                <p class="avenir primary-color font-16 mb-0">{{ $ml.get('solutions_services_text') }}</p>
                            </v-flex>
                        </v-layout>
                </v-flex>
                
                <v-flex md5>
                    <v-hover>
                        <div slot-scope="{ hover }">
                        <v-img
                            :src="image1" :lazy-src="image1"
                            class="image"
                            :class="{'blue-overlay-services': !hover}"
                            alt="lorem"
                            width="100%"
                            style="height:400px;"
                        >
                        
                            <v-expand-transition>
                            <v-flex
                                v-if="hover"
                                class=" transition-fast-in-fast-out hover-img darken-2 v-card--reveal"
                                style="height: 80%; margin:30px"
                            >
                            <v-layout row wrap>
                                <v-flex ma-8>
                                    <h3 class="antenna font-26 white--text" > {{ $ml.get('image1_title') }} </h3>
                                    <ul>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image1_bullet1') }}</p> </li>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image1_bullet2') }}</p> </li>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image1_bullet3') }}</p> </li>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image1_bullet4') }}</p> </li>
                                    </ul>
                                         
                                </v-flex>                
                            </v-layout>
                            </v-flex>
                            </v-expand-transition>
                        </v-img>
                        </div>
                    </v-hover>
                </v-flex>
                <v-flex md7>
                    <v-hover>
                        <div slot-scope="{ hover }">
                        <v-img
                            :src="image2" :lazy-src="image2"
                            class="image"
                            :class="{'blue-overlay-services': !hover}"
                            alt="lorem"
                            width="100%"
                            style="height:400px;;"
                        >
                        
                            <v-expand-transition>
                            <v-flex
                                v-if="hover"
                                class=" transition-fast-in-fast-out hover-img darken-2 v-card--reveal"
                                style="height: 80%; margin:30px"
                            >
                            <v-layout row wrap>
                                <v-flex ma-8>
                                    <h3 class="antenna font-26 white--text" > {{ $ml.get('image2_title') }} </h3>
                                    <ul>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image2_bullet1') }}</p> </li>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image2_bullet2') }}</p> </li>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image2_bullet3') }}</p> </li>
                                    </ul>
                                </v-flex>                   
                            </v-layout>
                            </v-flex>
                            </v-expand-transition>
                        </v-img>
                        </div>
                    </v-hover>
                </v-flex>
                <v-flex md12>
                    <v-hover>
                        <div slot-scope="{ hover }">
                                <v-img
                                    :src="image3" :lazy-src="image3"
                                    class="image"
                                    :class="{'blue-overlay-services-large': !hover}"
                                    alt="lorem"
                                    width="100%"
                                    style="height:350px;"
                                >
                        
                            <v-expand-transition>
                            <v-flex v-if="hover" class=" transition-fast-in-fast-out hover-img darken-2 v-card--reveal" style="height: 88%; margin:30px">
                            <v-layout row wrap >
                                <v-flex ma-8>
                                    <h3 class="antenna font-26 white--text" > {{ $ml.get('image3_title') }} </h3>
                                    <ul>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image3_bullet1') }}</p> </li>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image3_bullet2') }}</p> </li>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image3_bullet3') }}</p> </li>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image3_bullet4') }}</p> </li>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image3_bullet5') }}</p> </li>
                                        <li class="white--text"><p class="avenir font-15 white--text mb-0">{{ $ml.get('image3_bullet6') }}</p> </li>
                                    </ul> 
                                </v-flex>                 
                            </v-layout>
                            </v-flex>
                            </v-expand-transition>
                        </v-img>
                        </div>
                    </v-hover>

                </v-flex>
                <v-flex xs12 my-12 :class="{'align-right': $vuetify.breakpoint.smAndUp, 'mx-12': $vuetify.breakpoint.xsOnly}">
                        <p class="antenna font-37 primary-color mb-0">{{$ml.get('solutions_services_title2')}}</p>
                        <v-layout style="display: flex; justify-content: flex-end;">
                            <v-flex md7>
                                <p class="avenir primary-color font-15 mb-0">{{ $ml.get('solutions_services_text2') }}</p>
                            </v-flex>
                        </v-layout>
                </v-flex>
            </v-layout>
        </div>
      </div>


    <!-- Portfolio -->

    <div class="primary-background" id="clients">
        <div class="max-width-1200 ma-auto">
        <v-layout row wrap mx-12 :class="{'pa-6': $vuetify.breakpoint.smAndUp}">
          <v-flex xs12 sx6  class="my-12">
            <p class="venera font-19 white--text mb-0">{{ $ml.get('clients') }}</p>
            <p class="antenna font-37 white--text mb-0">{{$ml.get('solutions_clients_title')}}</p>
            <p class="avenir white--text font-16 mb-0">{{ $ml.get('solutions_clients_text') }}</p>
            <v-btn class="btn-text-white btn-secondary max-width-300 mt-8" depressed @click="$goTo('/portfolio', 'portfolio')"> 
                {{ $ml.get('button_portfolio') }} 
                <v-img :src="right_arrow_white" :lazy-src="right_arrow_white" style="width:18px; cursor:pointer" class="ml-2" ></v-img>
              </v-btn>
          </v-flex>    

          <v-flex  sm12 row wrap  class="mb-12">
              <v-layout row wrap justify-center>
                <v-flex md4 class="center" v-for="(company, i) in companies" :key="i">
                    <img :src="company.logo" :lazy-src="company.logo"  class="my-4 logo"/>
                </v-flex>
            </v-layout>
                </v-flex>
                
              

            <v-flex sm10 offset-sm1 style="text-align:center">
                <p class="avenir font-16 white--text mb-0">  {{ $ml.get('solutions_contact_text') }}</p>
                <p class="mt-8"><a class="avenir font-16 secondary-color mb-0" href="mailto:solutions@driveop.com">solutions@driveop.com</a></p>
                <v-btn class="btn-text-white btn-secondary max-width-250 mt-8 mb-12" depressed @click="$goTo('/contactUs', 'contact')"> 
                    {{ $ml.get('button_contact') }} 
                    <v-img :src="right_arrow_white" :lazy-src="right_arrow_white" style="width:18px; cursor:pointer" class="ml-2" ></v-img>
                </v-btn>
            </v-flex>
            
          </v-layout>
        </div>
    </div>


    

  </v-container>
</template>
<script>
export default {
  data () {
    return {
      name: '',
      year_1_text: false,
      year_2_text: false,
      year_3_text: false,

      year1: require('../assets/img/images/2010.png'),
      year2: require('../assets/img/images/2015.png'),
      year3: require('../assets/img/images/2019.png'),
      image1: require('../assets/img/images/financial-service.png'),
      image2: require('../assets/img/images/business-service.png'),
      image3: require('../assets/img/images/legal-service.png'),
      work: require('../assets/img/images/work.jpg'),
      left_arrow: require('../assets/img/brand/driveop_logo2.png'),
      down_arrow: require('../assets/img/brand/driveop_logo_down.png'),
      right_arrow: require('../assets/img/brand/driveop_arrow_right.png'),
      right_arrow_white: require('../assets/img/brand/driveop_logo_white.png'),
      building: require('../assets/img/images/edificios.svg'),
      companies: [
        {
          logo: require('../assets/img/logos/gallo.png')
        },
        {
         logo: require('../assets/img/logos/gorila.png')
        },
        {
          logo: require('../assets/img/logos/niklaas.png')
        },
        {
          logo: require('../assets/img/logos/conttento.png')
        },
        {
          logo: require('../assets/img/logos/syma.png')
        },
        {
          logo: require('../assets/img/logos/leven.png')
        },
        {
          logo: require('../assets/img/logos/pintart.png')
        },
        {
          logo: require('../assets/img/logos/opengg.png')
        },
        {
          logo: require('../assets/img/logos/hackbrake.png')
        },
        {
          logo: require('../assets/img/logos/zafira.png')
        },
        {
          logo: require('../assets/img/logos/shootvenirs.png')
        },
        {
          logo: require('../assets/img/logos/vinologia.png')
        },
        {
          logo: require('../assets/img/logos/ioc.png')
        },
        {
          logo: require('../assets/img/logos/empowermind.png')
        },
        {
          logo: require('../assets/img/logos/cbd.png')
        },
        {
          logo: require('../assets/img/logos/buscarea.png')
        },
        {
          logo: require('../assets/img/logos/inverteca.png')
        }
      ],
    }
  },
  methods : {
      showText (year) {
          if (year == 1) {
               setTimeout(() => this.year_1_text = true, 500);
            this.year_2_text = false
            this.year_3_text = false
           
          }
          else if (year == 2) {
            this.year_1_text = false
            this.year_3_text = false
            setTimeout(() => this.year_2_text = true, 500);
          }
          else if (year == 3) {
            this.year_1_text = false
            this.year_2_text = false
            setTimeout(() => this.year_3_text = true, 500);
          }
      },
  }
}
</script>

<style scoped>
.v-card__title {
  justify-content: center;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color:#028b8f !important; 
}

.slide-x-transition-enter-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-x-transition-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}


.image-container {
  position: relative;
  text-align: center;
  color: white;
}

.point {
    outline: none;
    z-index: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgba(26, 26, 26, 0.85);
    border: 1px solid rgba(26, 26, 26, 0.85);
}


 .office {
  background: #10A2A4 !important;
  border:1px solid #10A2A4 !important;
}

 .office-text {
  color: #10A2A4 !important;
}

 .project {
  background: rgb(26,60,79) !important;
  border: rgb(26,60,79) !important;
}

.hover-img {
  background-color: #029599;
  opacity: 1;
  color: #454545;
  align-items: center;
}

.align-right {
    text-align:right;
}


  .map-layout {
    flex-direction: column-reverse
  }

.logo {
    width: 250px !important;
    height: 120px !important;
}

.blue-overlay {
    outline: 125px solid rgba(26,60,79,0.4) !important;
    outline-offset: -125px !important;
    overflow: hidden !important;
    position: relative !important;
    width: 100% !important;
}

.blue-overlay-services {
    outline: 200px solid rgba(26,60,79,0.4) !important;
    outline-offset: -200px !important;
    overflow: hidden !important;
    position: relative !important;
    width: 100% !important;
}

.blue-overlay-services-large {
    outline: 175px solid rgba(26,60,79,0.4) !important;
    outline-offset: -175px !important;
    overflow: hidden !important;
    position: relative !important;
    width: 100% !important;
}
</style>
